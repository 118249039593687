<script>
import { Component } from "vue-property-decorator";
import AbstractMenu from "@/layouts/AbstractMenu";
import { SERVICEPROVIDER } from "@/services/User";

@Component({
  name: "Menu",
})
class Menu extends AbstractMenu {
  data() {
    return {
      // menu things
      rounded: null,
      inset: true,
      mini: 1,
      homePage: {
        link: {
          name: "PHome",
        },
      },
      wannabe: SERVICEPROVIDER,
      section0: [
        {
          text: "topMenuSP.market",
          id: "SP.topMenu.market",
          icon: "mdi-format-list-bulleted-type",
          link: {
            name: "ServiceProviderMarket",
          },
        },
        {
          text: "topMenuSP.customer",
          id: "SP.topMenu.customer",
          icon: "mdi-format-list-bulleted-type",
          link: {
            name: "ServiceProviderCustomerMarket",
          },
        },
        {
          text: "topMenuSP.agenda",
          id: "SP.topMenu.agenda",
          icon: "mdi-calendar-month",
          link: {
            name: "ServiceProviderAgenda",
          },
        },
        /* {
                     text: 'topMenuSP.miniweb',
                     icon: 'mdi-monitor-cellphone-star',
                     link: {
                     name: 'ServiceProviderMiniWeb',
                     },
                   },
                   {
                     text: 'Accounting',
                     icon: 'mdi-calendar-month',
                     link: {
                       name: 'Home',
                     },
                   },
                   {
                     text: 'topMenuSP.rating',
                     icon: 'mdi-star',
                     link: {
                       name: 'ServiceProviderRating',
                     },
                   }, */
      ],
      side: 1,
      section1: [
        {
          text: "sideMenuSP.account",
          id: "SP.sideMenu.account",
          icon: "mdi-card-account-details-outline",
          link: {
            name: "ServiceProviderMyAccount",
          },
        },
        {
          text: "sideMenuSP.services",
          id: "SP.sideMenu.services",
          icon: "mdi-fountain-pen-tip",
          link: {
            name: "ServiceProviderMyServices",
          },
        },
        {
          text: "sideMenuSP.param",
          id: "SP.sideMenu.parameters",
          icon: "mdi-cog",
          link: {
            name: "ServiceProviderParameters",
          },
        },

        {
          text: "sideMenuSP.miniweb",
          id: "SP.sideMenu.miniweb",
          icon: "mdi-web",
          link: {
            name: "ServiceProviderWebAdm",
          },
        },

        /*
                   {
                     text: 'sideMenuSP.cost',
                     icon: 'mdi-currency-eur',
                     link: {
                       name: 'ServiceProviderCost',
                     },
                   },
                   */
        {
          text: "sideMenuSP.ressource",
          id: "SP.sideMenu.resource",
          icon: "mdi-account-key",
          link: {
            name: "ServiceProviderMyResource",
          },
        },
        /*
                   {
                     text: 'sideMenuSP.product',
                     icon: 'mdi-ballot-outline',
                     link: {
                       name: 'ServiceProviderMyProduct',
                     },
                   },
                   {
                     text: 'sideMenuSP.gdpr',
                     icon: 'mdi-laptop-off',
                     link: {
                       name: 'ServiceProviderGdpr',
                     },
                   }, */
      ],
    };
  }
}
export default Menu;
</script>
